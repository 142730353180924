/** @jsx jsx */

import { jsx, Button } from 'theme-ui';
import { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const COOKIE_KEY = 'acceptCookies';

const isBrowser = typeof window !== 'undefined';

const Cookie = () => {
  const {
    datoCmsGeneral: { cookieDescription, cookieButton }
  } = useStaticQuery(
    graphql`
      query {
        datoCmsGeneral {
          cookieDescription
          cookieButton
        }
      }
    `
  );

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!isBrowser) return;
    if (!!document.location.href.match(/ac=1/)) return;

    setIsVisible(!JSON.parse(localStorage.getItem(COOKIE_KEY)));
  }, []);

  const handleClick = e => {
    e.preventDefault();
    localStorage.setItem(COOKIE_KEY, true);
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div
      sx={{
        position: 'fixed',
        zIndex: 10000,
        right: 0,
        bottom: 0,
        width: ['100%', 400]
      }}
    >
      <div
        sx={{
          borderRadius: 'medium',
          boxShadow: 'small',
          bg: 'white100',
          fontSize: 1,
          p: 3,
          m: 3
        }}
      >
        <p sx={{ mb: 3 }}>{cookieDescription}</p>

        <Button variant="primary" onClick={handleClick}>
          {cookieButton}
        </Button>
      </div>
    </div>
  );
};

export default Cookie;
