/** @jsx jsx */

import React from 'react';
import { jsx, Box, Flex } from 'theme-ui';

export const CONTAINER_SPACE = 8;

export const Container = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <Flex
      ref={ref}
      sx={{
        justifyContent: 'center'
      }}
      {...props}
    >
      <Box
        sx={{
          width: ['100%', 1000]
        }}
      >
        {children}
      </Box>
    </Flex>
  );
});

export const Feature = ({ children, reverse, ...props }) => {
  const [content, animation] = children;

  return (
    <Container {...props}>
      <Flex
        sx={{
          alignItems: 'center',
          flexDirection: ['column', reverse ? 'row-reverse' : 'row']
        }}
      >
        <Box
          sx={{
            width: ['100%', '50%'],
            px: 3,
            mb: [6, 0]
          }}
        >
          {content}
        </Box>
        <Box
          sx={{
            width: ['100%', '50%'],
            minHeight: '100%',
            px: 3
          }}
        >
          {animation}
        </Box>
      </Flex>
    </Container>
  );
};

export const Divider = props => {
  return (
    <Box
      sx={{
        width: 60,
        height: 4,
        bg: 'cream100',
        borderRadius: 'xlarge'
      }}
      {...props}
    />
  );
};
